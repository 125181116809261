//interfaces import
import {
  CentralPhotovoltaiques,
  Chauffe,
  HelpsOptions,
  ICommandeData,
  IPacAirAir,
  ISageProduct,
  IScenario,
  IStudyInformation,
  IUserAuthInfo,
  Isolation,
  LoanSimulationResult,
  ProductCommonBases,
} from "../../../interfaces/generalInterfaces";
import {
  IDataProduct,
  IProjectKPI,
  TFraisSupp,
  TSingleHelp,
} from "../../../interfaces/renoglobaleInterface";

//dictionaries import
import {
  CRMlabelToValueDictionnary,
  aideTypeToCRMaideId,
} from "@web/shared/dist/datas/CRMvalueToLabelDictionary";
import { energiePriceNov2023Data } from "@web/shared/dist/datas/dataHypotethis";

//utils import
import { capitalize } from "../../../utils/stringUtils";
import { formatPhoneNumber } from "../../../utils/utilsFunctions";

interface IDocumentsBdc {
  name: string;
  base64: string;
}

interface IProps {
  studyInformation: IStudyInformation;
  userAuthInfo: IUserAuthInfo;
  scenarioChosen?: IScenario;
  idDevisBecqe: string;
  documents: IDocumentsBdc[];
  cartProducts?: IDataProduct[];
  SAGEItemsInfo?: ISageProduct[];
  saveWithoutCreateBDC?: boolean;
  coupons?: {
    label: string;
    type: string;
    value: number;
    isMembershipCode: boolean;
  }[];
  RGProjectKPI?: IProjectKPI | null;
  devisOptions: HelpsOptions;
  financingInformationFormatForCRM?: {
    isLoanOptionChosen: boolean;
    fundingPeriod: number;
    amount: number;
    personalContributionAmount: number;
    interestRate: string;
    isDeferralOption: boolean;
    hasCoBorrower: boolean;
    hasBorrowerInsurance: boolean;
    loanSimulationResult: LoanSimulationResult;
  };
  bdcStatus: string | undefined;
}

//exported functions
export const formatBDCPayload = ({
  studyInformation,
  scenarioChosen,
  idDevisBecqe,
  documents,
  cartProducts,
  SAGEItemsInfo,
  userAuthInfo,
  saveWithoutCreateBDC = false,
  coupons,
  RGProjectKPI,
  devisOptions,
  financingInformationFormatForCRM,
  bdcStatus,
}: IProps): ICommandeData => {
  const addressArray: string[] | undefined =
    studyInformation.address?.split(", ");
  const roof_area_to_equip = studyInformation.roof_area_to_equip;

  const allExtraFeesRg =
    cartProducts?.reduce((extraCharges: TFraisSupp[], product) => {
      return product?.extra_charges_added
        ? [...extraCharges, ...product.extra_charges_added]
        : [...extraCharges];
    }, []) || [];

  let solar_potential_area = undefined;
  switch (true) {
    case roof_area_to_equip &&
      roof_area_to_equip >= 0 &&
      roof_area_to_equip <= 15:
      solar_potential_area = 629950000;
      break;
    case roof_area_to_equip &&
      roof_area_to_equip >= 16 &&
      roof_area_to_equip <= 25:
      solar_potential_area = 629950001;
      break;
    case roof_area_to_equip &&
      roof_area_to_equip >= 26 &&
      roof_area_to_equip <= 35:
      solar_potential_area = 629950002;
      break;
    case roof_area_to_equip &&
      roof_area_to_equip >= 36 &&
      roof_area_to_equip <= 55:
      solar_potential_area = 629950003;
      break;
    case roof_area_to_equip && roof_area_to_equip > 55:
      solar_potential_area = 629950004;
      break;
    default:
      break;
  }

  const scenarioData = {
    battery: {
      battery_pose_price_without_tax:
        scenarioChosen?.battery_pose_price_without_tax,
      battery_pose_sage_reference: scenarioChosen?.battery_pose_sage_reference,
      battery_price_without_tax: scenarioChosen?.battery_price_without_tax,
      battery_sage_reference: scenarioChosen?.battery_sage_reference,
    },
    kit_home_management: {
      kit_pose_price_without_tax: scenarioChosen?.kit_pose_price_without_tax,
      kit_pose_sage_reference: scenarioChosen?.kit_pose_sage_reference,
      kit_price_without_tax: scenarioChosen?.kit_price_without_tax,
      kit_sage_reference: scenarioChosen?.kit_sage_reference,
    },
    pv: {
      pose_price_without_tax: scenarioChosen?.pv_pose_price_without_tax,
      pose_sage_reference: scenarioChosen?.pv_pose_sage_reference,
      pv_price_without_tax: scenarioChosen?.pv_price_without_tax,
      pv_sage_reference: scenarioChosen?.pv_sage_reference,
    },
  };

  /**
   * in this function we check if there are batterie and / or kit home and we build the json consequently for becqe study only
   * */
  const getSubsituedProducts = () => {
    const batteryData = {
      price: scenarioData.battery.battery_price_without_tax,
      tvaCode: CRMlabelToValueDictionnary(
        "tvaCode",
        scenarioChosen?.tva?.toString()
      ),
      quantity: scenarioChosen?.number_of_batteries || 0,
      id_Sage: scenarioData?.battery.battery_sage_reference,
      //battery pose product
      poseProduct: {
        id_Sage: scenarioData?.battery.battery_pose_sage_reference,
        price: scenarioData?.battery.battery_pose_price_without_tax,
        tvaCode: CRMlabelToValueDictionnary(
          "tvaCode",
          scenarioChosen?.tva?.toString()
        ),
        quantity: scenarioChosen?.number_of_batteries || 0,
      },
    };

    const kitHomeManagementData = {
      price: scenarioData.kit_home_management.kit_price_without_tax,
      tvaCode: CRMlabelToValueDictionnary(
        "tvaCode",
        scenarioChosen?.tva?.toString()
      ),
      quantity: 1,
      id_Sage: scenarioData?.kit_home_management.kit_sage_reference,
      //kit home management pose product
      poseProduct: {
        id_Sage: scenarioData?.kit_home_management.kit_pose_sage_reference,
        price: scenarioData?.kit_home_management.kit_pose_price_without_tax,
        tvaCode: CRMlabelToValueDictionnary(
          "tvaCode",
          scenarioChosen?.tva?.toString()
        ),
        quantity: 1,
        ...(scenarioData?.kit_home_management.kit_sage_reference && {
          key: "value",
        }),
      },
    };

    return [
      ...(scenarioChosen?.number_of_batteries ? [batteryData] : []),
      ...(scenarioChosen?.kit_home_management &&
      !scenarioData?.pv?.pv_sage_reference?.includes("0EN")
        ? [kitHomeManagementData]
        : []), // For Enphase products there is a Kit home management directly integrated to the PV product. So it exists (kit_home_management=true) but not as a separate product.
    ];
  };

  /**
   * in this function we check if there are batterie and / or kit home and we build the json consequently for RG study only
   * */
  const getPVSubsituedRGProducts = (product: IDataProduct) => {
    const battery = product.product_characteristics.available_batteries?.find(
      (battery) =>
        battery.sage_reference ===
        product.selected_characteristics.battery_sage_reference
    );

    const batteryData = {
      price: battery?.price_without_tax,
      tvaCode: CRMlabelToValueDictionnary(
        "tvaCode",
        battery?.tva_percentage?.toString()
      ),
      quantity: product.selected_characteristics.n_batteries || 0,
      id_Sage: battery?.sage_reference,
      poseProduct: {
        id_Sage: battery?.installation_sage_reference,
        price: battery?.installation_price_without_tax,
        tvaCode: CRMlabelToValueDictionnary(
          "tvaCode",
          battery?.installation_tva_percentage?.toString()
        ),
        quantity: product.selected_characteristics.n_batteries ? 1 : 0, // Same installation price if we need to install one or more batteries
      },
    };

    const kitHomeManagementData = {
      price:
        product.product_characteristics.kit_home_management?.price_without_tax,
      tvaCode: CRMlabelToValueDictionnary(
        "tvaCode",
        product.product_characteristics.kit_home_management?.tva_percentage?.toString()
      ),
      quantity: 1,
      id_Sage:
        product?.product_characteristics?.kit_home_management?.sage_reference,
      poseProduct: {
        id_Sage:
          product?.product_characteristics?.kit_home_management
            ?.installation_sage_reference,
        price:
          product.product_characteristics.kit_home_management
            ?.installation_price_without_tax,
        tvaCode: CRMlabelToValueDictionnary(
          "tvaCode",
          product.product_characteristics.kit_home_management?.installation_tva_percentage?.toString()
        ),
        quantity: 1,
      },
    };

    return [
      ...(product.selected_characteristics.n_batteries !== 0
        ? [batteryData]
        : []),
      ...(product.selected_characteristics.kit_home_management &&
      !product.sage_reference?.includes("0EN") // For Enphase products there is a Kit home management directly integrated to the PV product. So it exists (kit_home_management=true) but not as a separate product.
        ? [kitHomeManagementData]
        : []),
    ];
  };

  const getPacAirAirSubsituedRGProducts = (product: IDataProduct) => {
    const pacAirAirInteriorUnits =
      product.selected_characteristics.rooms_information?.flatMap((room) =>
        room.interior_units.map((interior_unit) => {
          const productCharacteristicsFromSAGE = SAGEItemsInfo?.find(
            (productCharacteristics) =>
              productCharacteristics.AR_Ref ===
              interior_unit.interior_unit_sage_reference
          );
          return {
            price: interior_unit.interior_unit_price_without_tax,
            tvaCode: CRMlabelToValueDictionnary(
              "tvaCode",
              interior_unit.interior_unit_TVA_percentage?.toString()
            ),
            quantity: 1,
            id_Sage: interior_unit.interior_unit_sage_reference,
            poseProduct: {
              id_Sage: interior_unit.interior_unit_installation_sage_reference,
              price: interior_unit.interior_unit_installation_price_without_tax,
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                interior_unit.interior_unit_installation_TVA_percentage?.toString()
              ),
              quantity: 1,
            },
            writableProperties: [
              ...(productCharacteristicsFromSAGE?.[
                "Classe energétique (Froid/Chaud"
              ]
                ? [
                    {
                      valueString:
                        productCharacteristicsFromSAGE?.[
                          "Classe energétique (Froid/Chaud"
                        ],
                      name: "Classe énergetique",
                    },
                  ]
                : []),
              ...(product?.product_characteristics?.scop?.toString()
                ? [
                    {
                      value: product?.product_characteristics?.scop?.toString(),
                      name: "COP/SCOP",
                    },
                  ]
                : []),
              ...(productCharacteristicsFromSAGE?.Gamme
                ? [
                    {
                      valueString: productCharacteristicsFromSAGE?.Gamme,
                      name: "Gamme",
                    },
                  ]
                : []),
              ...(productCharacteristicsFromSAGE?.Marque
                ? [
                    {
                      valueString: productCharacteristicsFromSAGE?.Marque,
                      name: "Marque",
                    },
                  ]
                : []),
              ...(productCharacteristicsFromSAGE?.["Garantie Fabricant"]
                ? [
                    {
                      valueString:
                        productCharacteristicsFromSAGE?.["Garantie Fabricant"],
                      name: "Garantie Fabricant",
                    },
                  ]
                : []),
              ...(productCharacteristicsFromSAGE?.Poid?.toString()
                ? [
                    {
                      valueString:
                        productCharacteristicsFromSAGE?.Poid?.toString(),
                      name: "Poids",
                    },
                  ]
                : []),
              ...(productCharacteristicsFromSAGE?.Dimensions
                ? [
                    {
                      valueString: productCharacteristicsFromSAGE?.Dimensions,
                      name: "Taille",
                    },
                  ]
                : []),
              ...(interior_unit.surface_to_heat?.toString()
                ? [
                    {
                      value: interior_unit.surface_to_heat?.toString(),
                      name: "Surface chauffée (m²)",
                    },
                  ]
                : []),
            ],
          };
        })
      ) || [];

    return [...pacAirAirInteriorUnits];
  };

  /**
   * Function to build aides object
   * */
  const getAidesRGProduct = ({
    helps,
    devisOptions,
  }: {
    helps: TSingleHelp[];
    devisOptions: HelpsOptions;
  }) => {
    if (devisOptions.isHelpsDisplayed === false) return null;
    const formatHelps = helps
      .filter((helps) => helps.is_eligible && helps.value > 0)
      .map((help) => {
        return {
          amount: help.value,
          aideId: aideTypeToCRMaideId({
            aideType: help.name,
            isDev: process.env.REACT_APP_ENVIRONMENT === "development",
          }),
          isDeducted:
            help.help_type === "mpr"
              ? devisOptions.isMPRHelpsDeducted
              : help.help_type === "cee"
              ? devisOptions.isCEEHelpsDeducted
              : false,
        };
      });
    return formatHelps;
  };

  /**
   to test the call replace :
   EMailAddress: studyInformation.email,
   CommercialId: studyInformation.commercial_id,
   MobilePhone: formatPhoneNumber(studyInformation.phonenumber),

   with:
   EMailAddress: 'your mail',
   CommercialId: '5CC1097C-590D-EB11-A814-000D3AAFE41C',
   MobilePhone: "your number",
   * */

  /**
   * Function to build liste_produit object for RG study only
   * */
  let isFirstISOProduct = true;
  const formatListProductRG = ({
    cartProducts,
    productsFromSAGE,
    type,
  }: {
    cartProducts: IDataProduct[];
    productsFromSAGE: ISageProduct[] | undefined;
    type: string[];
  }) => {
    const newTab: any = [];
    cartProducts
      ?.filter(
        (product) => product.isSelected && type.includes(product.product_type)
      )
      .map((product) => {
        const productFromSAGE = productsFromSAGE?.find(
          (productCharacteristics) =>
            productCharacteristics.AR_Ref === product.sage_reference
        );

        switch (product.product_type) {
          case "PV":
            return newTab.push({
              subsituedProducts: getPVSubsituedRGProducts(product),
              writableProperties: [
                {
                  value: "1", //id for Autoconsommation avec vente du surplus
                  name: "Type de raccordement",
                },
                {
                  value: "1", //id for "Surimposition"
                  name: "Type de fixation",
                },
                {
                  valueString: "Noir",
                  name: "Couleur des modules choisie",
                },
                {
                  value:
                    CRMlabelToValueDictionnary(
                      "alimentation",
                      studyInformation.electric_installation_type?.toString()
                    )?.toString() || "1",
                  name: "Alimentation",
                },
              ],
              poseProduct: {
                id_Sage: product.sage_reference_installation,
                tvaCode: CRMlabelToValueDictionnary(
                  "tvaCode",
                  product.installation_tva_percentage.toString()
                ),
                quantity: 1,
                price: product.installation_price_without_tax,
              },
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                product.TVA_percentage.toString()
              ),
              quantity: 1,
              puissanceCentrale: product.product_characteristics.power,
              id_Sage: product.sage_reference,
              price: product.product_price_without_tax,
              aides: getAidesRGProduct({
                helps: product.help,
                devisOptions,
              }),
            } as ProductCommonBases & CentralPhotovoltaiques);
          case "BALLON":
          case "CET":
            return newTab.push({
              id_Sage: product.sage_reference,
              price: product.product_price_without_tax,
              cop_scop: product.product_characteristics.scop?.toString(),
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                product.TVA_percentage.toString()
              ),
              quantity: 1,
              producttypecode:
                product.product_type === "CET"
                  ? CRMlabelToValueDictionnary(
                      "productTypeCode",
                      "Chauffe - eau thermodynamique"
                    )
                  : CRMlabelToValueDictionnary(
                      "productTypeCode",
                      "Chauffe - eau électrique"
                    ),
              capacity:
                productFromSAGE && Number(productFromSAGE["Capacité (l)"]),
              puissance: product.product_characteristics.power,
              // tempsDeChauffeParHeure: "string", => TBD ?
              poseProduct: {
                id_Sage: product.sage_reference_installation,
                tvaCode: CRMlabelToValueDictionnary(
                  "tvaCode",
                  product.installation_tva_percentage.toString()
                ),
                quantity: 1,
                price: product.installation_price_without_tax,
              },
              aides: RGProjectKPI?.is_eligible
                ? null
                : getAidesRGProduct({
                    helps: product.help,
                    devisOptions,
                  }),
            } as ProductCommonBases & Chauffe);
          case "PACAIRO":
            return newTab.push({
              id_Sage: product.sage_reference,
              price: product.product_price_without_tax,
              producttypecode: CRMlabelToValueDictionnary(
                "productTypeCode",
                "Pompe à chaleur Air/Eau"
              ),
              cop_scop: product?.product_characteristics?.scop?.toString(),
              puissance: product.product_characteristics.power,
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                product.TVA_percentage.toString()
              ),
              quantity: 1,
              ecsInteg: product.product_characteristics.used_for_ecs,
              poseProduct: {
                id_Sage: product.sage_reference_installation,
                tvaCode: CRMlabelToValueDictionnary(
                  "tvaCode",
                  product.installation_tva_percentage.toString()
                ),
                quantity: 1,
                price: product.installation_price_without_tax,
              },
              aides: RGProjectKPI?.is_eligible
                ? null
                : getAidesRGProduct({
                    helps: product.help,
                    devisOptions,
                  }),
              // WAITING FOR CRM TO DEBUG
              // writableProperties: [
              //   {
              //     value:
              //       CRMlabelToValueDictionnary(
              //         "alimentation",
              //         studyInformation.electric_installation_type?.toString()
              //       )?.toString() || "1",
              //     name: "Alimentation",
              //   },
              // ],
            } as ProductCommonBases & Chauffe);
          case "PACAIRAIR":
            return newTab.push({
              id_Sage: product.sage_reference,
              price: product.product_price_without_tax,
              puissance: product.product_characteristics.power,
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                product.TVA_percentage.toString()
              ),
              quantity: 1,
              poseProduct: {
                id_Sage: product.sage_reference_installation,
                tvaCode: CRMlabelToValueDictionnary(
                  "tvaCode",
                  product.installation_tva_percentage.toString()
                ),
                quantity: 1,
                price: product.installation_price_without_tax,
              },
              aides: RGProjectKPI?.is_eligible
                ? null
                : getAidesRGProduct({
                    helps: product.help,
                    devisOptions,
                  }),
              writableProperties: [
                ...(productFromSAGE?.["Classe energétique (Froid/Chaud"]
                  ? [
                      {
                        valueString:
                          productFromSAGE?.["Classe energétique (Froid/Chaud"],
                        name: "Classe énergetique",
                      },
                    ]
                  : []),
                ...(product?.product_characteristics?.scop?.toString()
                  ? [
                      {
                        value:
                          product?.product_characteristics?.scop?.toString(),
                        name: "COP/SCOP",
                      },
                    ]
                  : []),
                ...(productFromSAGE?.Gamme
                  ? [
                      {
                        valueString: productFromSAGE?.Gamme,
                        name: "Gamme",
                      },
                    ]
                  : []),
                ...(productFromSAGE?.Marque
                  ? [
                      {
                        valueString: productFromSAGE?.Marque,
                        name: "Marque",
                      },
                    ]
                  : []),
                ...(productFromSAGE?.["Garantie Fabricant"]
                  ? [
                      {
                        valueString: productFromSAGE?.["Garantie Fabricant"],
                        name: "Garantie Fabricant",
                      },
                    ]
                  : []),
                ...(productFromSAGE?.Poid?.toString()
                  ? [
                      {
                        valueString: productFromSAGE?.Poid?.toString(),
                        name: "Poids",
                      },
                    ]
                  : []),
                ...(product?.selected_characteristics.surface_to_heat?.toString()
                  ? [
                      {
                        value:
                          product?.selected_characteristics.surface_to_heat?.toString(),
                        name: "Surface chauffée (m²)",
                      },
                    ]
                  : []),
                ...(productFromSAGE?.Dimensions
                  ? [
                      {
                        valueString: productFromSAGE?.Dimensions,
                        name: "Taille",
                      },
                    ]
                  : []),
              ],
              subsituedProducts: getPacAirAirSubsituedRGProducts(product),
            } as ProductCommonBases & IPacAirAir);
          case "ITE":
          case "ITI":
          case "ISOA":
          case "ISO":
          case "ISOP":
            const tab = {
              id_Sage: product.sage_reference,
              price: product.product_price_without_tax,
              producttypecode: CRMlabelToValueDictionnary(
                "productTypeCode",
                "Isolation"
              ),
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                product.TVA_percentage.toString()
              ),
              quantity: product.selected_characteristics.surface_to_isolate
                ? Math.round(
                    product.selected_characteristics.surface_to_isolate
                  )
                : 0,
              poseProduct: {
                id_Sage: product.sage_reference_installation,
                tvaCode: CRMlabelToValueDictionnary(
                  "tvaCode",
                  product.installation_tva_percentage.toString()
                ),
                quantity: product.selected_characteristics.surface_to_isolate
                  ? Math.round(
                      product.selected_characteristics.surface_to_isolate
                    )
                  : 0,
                price: product.installation_price_without_tax,
              },
              aides: RGProjectKPI?.is_eligible
                ? isFirstISOProduct
                  ? getAidesRGProduct({
                      helps: RGProjectKPI.reno_globale_helps,
                      devisOptions,
                    })
                  : null
                : getAidesRGProduct({
                    helps: product.help,
                    devisOptions,
                  }),
              isolatedZones: [
                {
                  zoneName: studyInformation.type_de_murs?.join(", "),
                  surface: product.selected_characteristics.surface_to_isolate
                    ? Math.round(
                        product.selected_characteristics.surface_to_isolate
                      )
                    : null,
                  typedeParoi:
                    studyInformation.type_de_murs &&
                    CRMlabelToValueDictionnary(
                      "wallType",
                      studyInformation.type_de_murs[0]
                    ),
                  typeOperation: [
                    CRMlabelToValueDictionnary(
                      "operationType",
                      product.product_type
                    ),
                  ],
                },
              ],
              typeOperation: CRMlabelToValueDictionnary(
                "operationType",
                "BAR-EN-102 ITE"
              ),
            } as ProductCommonBases & Isolation;
            isFirstISOProduct = false;
            return newTab.push(tab);
          case "VMC": // Simple flux only
            return newTab.push({
              id_Sage: product.sage_reference,
              price: product.product_price_without_tax,
              producttypecode: CRMlabelToValueDictionnary(
                "productTypeCode",
                "LED" // TO UPDATE WHEN CRM WILL BE READY
              ),
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                product.TVA_percentage.toString()
              ),
              quantity: 1,
              poseProduct: {
                id_Sage: product.sage_reference_installation,
                tvaCode: CRMlabelToValueDictionnary(
                  "tvaCode",
                  product.installation_tva_percentage.toString()
                ),
                quantity: 1,
                price: product.installation_price_without_tax,
              },
              aides: RGProjectKPI?.is_eligible
                ? null
                : getAidesRGProduct({
                    helps: product.help,
                    devisOptions,
                  }),
              typeOperation: CRMlabelToValueDictionnary(
                "operationType",
                "CEE BAR-TH-127"
              ),
            });
          case "FENETRES":
            const tabFenetres = {
              id_Sage: product.sage_reference,
              price: product.product_price_without_tax,
              producttypecode: CRMlabelToValueDictionnary(
                "productTypeCode",
                "Isolation"
              ),
              tvaCode: CRMlabelToValueDictionnary(
                "tvaCode",
                product.TVA_percentage.toString()
              ),
              quantity: product?.selected_characteristics?.productQuantity || 1,
              writableProperties: [
                ...(productFromSAGE?.["Résistance Thermique "]
                  ? [
                      {
                        valueString: productFromSAGE?.["Résistance Thermique "],
                        name: "Résistance thermique",
                      },
                    ]
                  : []),
                ...(productFromSAGE?.["type de vitrage "]
                  ? [
                      {
                        valueString: productFromSAGE?.["type de vitrage "],
                        name: "Type de vitrage",
                      },
                    ]
                  : []),
                ...(productFromSAGE?.["Facteur solaire"]
                  ? [
                      {
                        valueString: productFromSAGE?.["Facteur solaire"],
                        name: "Facteur solaire",
                      },
                    ]
                  : []),
                ...(productFromSAGE?.["Coef de transmission surfacique"]
                  ? [
                      {
                        valueString:
                          productFromSAGE?.["Coef de transmission surfacique"],
                        name: "Coefficient de transmission surfacique",
                      },
                    ]
                  : []),
                ...[
                  {
                    valueString:
                      product?.selected_characteristics?.windows
                        ?.map((w) => {
                          return `\n- ${w.width} x ${w.height}m (${capitalize(
                            w.type
                          )})`;
                        })
                        .join("") || "",
                    name: "Dimensions",
                  },
                ],
              ],
              poseProduct: {
                id_Sage: product.sage_reference_installation,
                tvaCode: CRMlabelToValueDictionnary(
                  "tvaCode",
                  product.installation_tva_percentage.toString()
                ),
                quantity:
                  product?.selected_characteristics?.productQuantity || 1,
                price: product.installation_price_without_tax,
              },
              aides: RGProjectKPI?.is_eligible
                ? null
                : getAidesRGProduct({
                    helps: product.help,
                    devisOptions,
                  }),
            } as ProductCommonBases;

            return newTab.push(tabFenetres);
          default:
            console.log(`Product type ${product.product_type} not handled`);
        }

        return product;
      });

    return newTab;
  };

  const formatExtraChargeProduct = ({
    extraFees,
  }: {
    extraFees?: TFraisSupp[];
  }) => {
    // For each extra charge return the formatted object
    return (
      extraFees?.map((extraCharge) => {
        return {
          productName: extraCharge.product_name,
          tvaCode:
            CRMlabelToValueDictionnary(
              "tvaCode",
              extraCharge.TVA_percentage?.toString()
            ) || 629950002, // 629950002 = 20%
          quantity: 1,
          montant_ht: extraCharge.price_without_tax?.toString() || "",
          description: extraCharge.description || "",
        };
      }) || []
    );
  };

  const formatFreeProduct = ({
    cartProducts,
  }: {
    cartProducts?: IDataProduct[];
  }) => {
    // For each product with product_type = "AUTRE" return the formatted object
    return (
      cartProducts
        ?.filter((product) => product.product_type === "AUTRE")
        .map((product) => {
          return {
            productName: product.product_name,
            tvaCode:
              CRMlabelToValueDictionnary(
                "tvaCode",
                product.TVA_percentage?.toString()
              ) || 629950002, // 629950002 = 20%
            quantity: 1,
            montant_ht: product.product_price_without_tax?.toString() || "",
            description:
              product.selected_characteristics?.free_product_description || "",
          };
        }) || []
    );
  };

  /**
   * Function to build vos_conso object for RG study only
   * */
  const formatConsoRG = (studyInformation: IStudyInformation) => {
    const chauffage = [
      {
        source: "Electricité",
        factureEuro: studyInformation.electricite_chauffage_euros ?? 0,
        temperature: studyInformation.electricite_temperature_confort,
        typeChauffage: studyInformation.electricite_type_chauffage,
        surface: studyInformation.electricite_surface_chauffee ?? 0,
      },
      {
        source: "Gaz Naturel",
        factureEuro: studyInformation.gaz_chauffage_euros ?? 0,
        temperature: studyInformation.gaz_temperature_confort,
        typeChauffage: studyInformation.gaz_type_chauffage,
        surface: studyInformation.gaz_surface_chauffee ?? 0,
      },
      {
        source: "GPL",
        factureEuro: studyInformation.gpl_chauffage_euros ?? 0,
        temperature: studyInformation.gpl_temperature_confort,
        typeChauffage: studyInformation.gpl_type_chauffage,
        surface: studyInformation.gpl_surface_chauffee ?? 0,
      },
      {
        source: "Fioul",
        factureEuro: studyInformation.fioul_chauffage_euros ?? 0,
        temperature: studyInformation.fioul_temperature_confort,
        typeChauffage: studyInformation.fioul_type_chauffage,
        surface: studyInformation.fioul_surface_chauffee ?? 0,
      },
      {
        source: "Bois",
        factureEuro: studyInformation.bois_consommation_euros ?? 0,
        temperature: studyInformation.bois_temperature_confort,
        typeChauffage: studyInformation.bois_type_chauffage,
        surface: studyInformation.bois_surface_chauffee ?? 0,
      },
    ];

    const ecs = [
      {
        source: "Electricité",
        factureEuro: studyInformation.electricite_ecs_euros ?? 0,
        boilerType:
          studyInformation.electricite_type_ballon_eau_chaude &&
          studyInformation.electricite_type_ballon_eau_chaude[0],
        installYear:
          studyInformation.electricite_installation_year || undefined,
        storage: studyInformation.electricite_storage_capacity ?? 0,
      },
      {
        source: "Gaz Naturel",
        factureEuro: studyInformation.gaz_ecs_euros ?? 0,
        boilerType: null,
        installYear: undefined,
        storage: studyInformation.gaz_storage_capacity ?? 0,
      },
      {
        source: "Fioul",
        factureEuro: studyInformation.fioul_ecs_euros ?? 0,
        boilerType: null,
        installYear: undefined,
        storage: studyInformation.fioul_storage_capacity ?? 0,
      },
      {
        source: "GPL",
        factureEuro: studyInformation.gpl_ecs_euros ?? 0,
        boilerType: null,
        installYear: undefined,
        storage: studyInformation.gpl_storage_capacity ?? 0,
      },
    ];

    const bestSourceChauffage = chauffage.reduce((prev, current) => {
      return prev.factureEuro > current.factureEuro ? prev : current;
    });

    const bestECS = ecs.reduce((prev, current) => {
      return prev.factureEuro > current.factureEuro ? prev : current;
    });

    return {
      chauffage: {
        sourceEnergieElec:
          studyInformation.electricite_principaux_equipments?.includes(
            "electricite"
          ),
        sourceEnergie: CRMlabelToValueDictionnary(
          "sourceEnergie",
          bestSourceChauffage.source
        ),
        estimationFactureChauffage: chauffage
          .reduce((acc, obj) => {
            return acc + obj.factureEuro;
          }, 0)
          .toString(),
        prixElectricite: energiePriceNov2023Data.Electricité.toString(),
        surfaceChauffee: bestSourceChauffage.surface,
        temperatureDeConfort: bestSourceChauffage.temperature?.toString(),
        typeChauffage:
          bestSourceChauffage.typeChauffage &&
          CRMlabelToValueDictionnary(
            "typeChauffage",
            bestSourceChauffage.typeChauffage[0]
          ),
      },
      ecs: {
        boilerEnergyTypeElec:
          studyInformation.electricite_principaux_equipments?.includes(
            "eauChaudeSanitaire"
          ) || false,
        boilerEnergyType: CRMlabelToValueDictionnary(
          "sourceEnergie",
          bestECS.source
        ),
        boilerType: CRMlabelToValueDictionnary(
          "boilertype",
          bestECS.boilerType
        ),
        anneeInstallation: bestECS.installYear?.toString(),
        capaciteStockage: CRMlabelToValueDictionnary(
          "stockageCapacity",
          bestECS.storage.toString()
        ),
      },
    };
  };

  return {
    prospect: {
      FirstName: studyInformation.firstname,
      LastName: studyInformation.lastname,
      EMailAddress: studyInformation.email,
      MobilePhone: formatPhoneNumber(studyInformation.phonenumber),
      PostalCode: studyInformation.zipcode,
      Street: addressArray && addressArray[0],
      City: addressArray && addressArray[1],
      CommercialId: userAuthInfo.crm_user_id, // id to use for test => "5CC1097C-590D-EB11-A814-000D3AAFE41C"
      lng: studyInformation.longitude
        ? String(studyInformation.longitude)
        : null,
      lat: studyInformation.latitude ? String(studyInformation.latitude) : null,
    },

    etude_logement: {
      surface_habitable: studyInformation.house_surface
        ? String(studyInformation.house_surface)
        : null,
      cover_type: CRMlabelToValueDictionnary(
        "natureDuSupport",
        studyInformation.roof_subtype || studyInformation.roof_type
      ),
      type_occupation:
        CRMlabelToValueDictionnary(
          "occupationType",
          studyInformation?.occupation_type
        ) || 629950000,
      number_of_inhabitants: studyInformation.number_of_occupants,
      inclinaison_du_pan: CRMlabelToValueDictionnary(
        "roofInclinaison",
        studyInformation.roof_inclinaison?.toString()
      ),
      electrical_installation_type:
        CRMlabelToValueDictionnary(
          "typeAlimentation",
          studyInformation.electric_installation_type
        ) || 629950000,
      year_of_construction: studyInformation?.annee_de_construction?.toString(),
      number_of_levels: CRMlabelToValueDictionnary(
        "floorNumber",
        (studyInformation.nombre_de_niveaux &&
          (studyInformation.nombre_de_niveaux > 3
            ? "3+"
            : studyInformation.nombre_de_niveaux.toString())) ||
          "1"
      ),
      ceiling_height: studyInformation.hauteur_sous_plafond
        ? (studyInformation.hauteur_sous_plafond / 100).toString()
        : null,
      construction_square: studyInformation.surface_au_sol,
      wall_type:
        studyInformation.type_de_murs &&
        CRMlabelToValueDictionnary(
          "wallType",
          studyInformation.type_de_murs[0]
        ),
      isolated_walls:
        studyInformation.annee_isolation_murs === "None"
          ? 629950001
          : 629950000,
      number_of_rooms: studyInformation.nombre_de_pieces,
      attic_type:
        studyInformation.type_de_combles &&
        CRMlabelToValueDictionnary(
          "atticType",
          studyInformation.type_de_combles[0]
        ),
      ventilation_type:
        studyInformation.type_de_ventilation &&
        CRMlabelToValueDictionnary(
          "ventilationType",
          studyInformation.type_de_ventilation[0]
        ),
      basement_type:
        studyInformation.type_de_sous_sol &&
        CRMlabelToValueDictionnary(
          "basementType",
          studyInformation.type_de_sous_sol[0]
        ),
      garageattenantalamaison:
        studyInformation.type_de_garage &&
        (studyInformation?.type_de_garage[0] === "interieur" ||
          studyInformation?.type_de_garage[0] === "independant")
          ? 0
          : 1,
      configurationgarage:
        studyInformation.type_de_garage &&
        CRMlabelToValueDictionnary(
          "garageConfiguration",
          studyInformation.type_de_garage[0]
        ),
    },

    potentiel_solaire: {
      exposure: CRMlabelToValueDictionnary(
        "orientation",
        studyInformation.roof_exposition
      ),
      solar_potential_area: solar_potential_area,
      impact_masque_solaire: CRMlabelToValueDictionnary(
        "impactMasqueSolaire",
        studyInformation.roof_solar_mask?.toString()
      ),
    },

    vos_conso:
      studyInformation.study_type === "renovation-globale"
        ? formatConsoRG(studyInformation)
        : {
            chauffage: {
              sourceEnergieElec: studyInformation.is_electric_heater === true,
            },
            ecs: {
              boilerEnergyTypeElec:
                studyInformation.is_electric_water_heater === true,
            },
            homeysPathCompleted: studyInformation.is_homeys_done,
          },

    liste_produit:
      studyInformation.study_type === "renovation-globale"
        ? {
            centralPhotovoltaiques: formatListProductRG({
              cartProducts: cartProducts || [],
              productsFromSAGE: SAGEItemsInfo,
              type: ["PV"],
            }),
            chauffesEau: formatListProductRG({
              cartProducts: cartProducts || [],
              productsFromSAGE: SAGEItemsInfo,
              type: ["CET", "BALLON"],
            }),

            pompesChaleurAirEau: formatListProductRG({
              cartProducts: cartProducts || [],
              productsFromSAGE: SAGEItemsInfo,
              type: ["PACAIRO"],
            }),
            pompesChaleurAirAir: formatListProductRG({
              cartProducts: cartProducts || [],
              productsFromSAGE: SAGEItemsInfo,
              type: ["PACAIRAIR"],
            }),
            isolations: formatListProductRG({
              cartProducts: cartProducts || [],
              productsFromSAGE: SAGEItemsInfo,
              type: ["ITE", "ITI", "ISOA", "ISO", "ISOP"],
            }),
            relampingLEDs: formatListProductRG({
              cartProducts: cartProducts || [],
              productsFromSAGE: SAGEItemsInfo,
              type: ["VMC"],
            }),
            fenetres: formatListProductRG({
              cartProducts: cartProducts || [],
              productsFromSAGE: SAGEItemsInfo,
              type: ["FENETRES"],
            }),
            noReferencedProducts: [
              ...formatFreeProduct({ cartProducts }),
              ...formatExtraChargeProduct({
                extraFees: allExtraFeesRg,
              }),
            ],
          }
        : {
            centralPhotovoltaiques: [
              {
                aides: devisOptions.isHelpsDisplayed
                  ? [
                      {
                        amount: scenarioChosen?.total_helps,
                        aideId: aideTypeToCRMaideId({
                          aideType: "Prime à l'investissement",
                          isDev:
                            process.env.REACT_APP_ENVIRONMENT === "development",
                        }),
                        isDeducted: false, // Prime à l'investissement is never deductible
                      },
                    ]
                  : null,
                id_Sage: scenarioChosen?.pv_sage_reference,
                puissanceCentrale: scenarioChosen?.power_needed,
                price: scenarioChosen?.pv_price_without_tax || null,
                tvaCode: CRMlabelToValueDictionnary(
                  "tvaCode",
                  scenarioChosen?.tva?.toString()
                ),
                quantity: 1,
                poseProduct: {
                  id_Sage: scenarioData?.pv.pose_sage_reference
                    ? scenarioData?.pv.pose_sage_reference
                    : null,
                  price: scenarioData?.pv.pose_price_without_tax
                    ? scenarioData?.pv.pose_price_without_tax
                    : null,
                  tvaCode: CRMlabelToValueDictionnary(
                    "tvaCode",
                    scenarioChosen?.tva?.toString()
                  ),
                  quantity: 1,
                },
                subsituedProducts: getSubsituedProducts() || [],
                writableProperties: [
                  {
                    value: "1", //id for Autoconsommation avec vente du surplus
                    name: "Type de raccordement",
                  },
                  {
                    value: "1", //id for "Surimposition"
                    name: "Type de fixation",
                  },
                  {
                    value:
                      CRMlabelToValueDictionnary(
                        "alimentation",
                        studyInformation.electric_installation_type?.toString()
                      )?.toString() || "1",
                    name: "Alimentation",
                  },
                  {
                    valueString: "Noir",
                    name: "Couleur des modules choisie",
                  },
                  {
                    value: String(
                      scenarioChosen?.selfconsumption_rate &&
                        (scenarioChosen?.selfconsumption_rate * 100 > 80
                          ? 80
                          : Math.round(
                              scenarioChosen.selfconsumption_rate * 100
                            ))
                    ),
                    name: "Pourcentage autoconsomation",
                  },
                ],
              },
            ],
            noReferencedProducts: formatExtraChargeProduct({
              extraFees: scenarioChosen?.extra_fees || undefined,
            }),
          },
    code_promo: {
      codePromotion: {
        label:
          coupons?.find((coupon) => !coupon.isMembershipCode)?.label || null,
        value:
          coupons?.find((coupon) => !coupon.isMembershipCode)?.value || null,
      },
      codeParrainage:
        coupons?.find((coupon) => coupon.isMembershipCode)?.label || null,
    },

    documents: documents,
    idDevisBecqe: idDevisBecqe,
    typeEtude:
      CRMlabelToValueDictionnary("studyType", studyInformation.study_type) ||
      629950038,
    onlyquote: saveWithoutCreateBDC,
    financementInfos:
      financingInformationFormatForCRM &&
      financingInformationFormatForCRM.isLoanOptionChosen
        ? {
            typedeFinancement: 629950001, // 629950001(=partenaire financier)
            organismedeFinancementId: "27238673-27c5-ea11-a812-000d3abb516e", //= id de prod. Id de dev = "4b6e3471-9ddc-ea11-a813-000d3aafe55d"
            amountLease: (
              financingInformationFormatForCRM.amount -
              financingInformationFormatForCRM.personalContributionAmount
            )
              .toString()
              .replace(".", ","),
            coEmprunteur: financingInformationFormatForCRM.hasCoBorrower,
            nombreEcheance: financingInformationFormatForCRM.fundingPeriod * 12,
            report:
              financingInformationFormatForCRM.loanSimulationResult
                .deferralDurationMonth,
            montantdesEcheances:
              financingInformationFormatForCRM.loanSimulationResult.monthlyPayment
                .toString()
                .replace(".", ","),
            acompte: financingInformationFormatForCRM.personalContributionAmount
              .toString()
              .replace(".", ","),
            tauxDebiteurFixe:
              financingInformationFormatForCRM.loanSimulationResult.tauxDebiteurFixe
                .toString()
                .replace(".", ","),
            TAEG: financingInformationFormatForCRM.loanSimulationResult.TAEG.toString().replace(
              ".",
              ","
            ),
            assuranceEmprunteur:
              financingInformationFormatForCRM.hasBorrowerInsurance,
          }
        : {
            typedeFinancement: 629950000, // 629950000(=comptant)
            organismedeFinancementId: null,
            amountLease: null,
            coEmprunteur: null,
            nombreEcheance: null,
            report: null,
            montantdesEcheances: null,
            acompte: null,
            tauxDebiteurFixe: null,
            TAEG: null,
            assuranceEmprunteur: null,
          },
    // In case the sale order (bdc) is canceled the subscription to a new sale order is considered as a new one (and not a replacement)
    orderToCancelId:
      bdcStatus?.toLowerCase() === "annulé" // "annulé" => status received from CRM
        ? null
        : studyInformation.last_bdc_id || null,
  };
};
